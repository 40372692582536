<template><Logo/></template>

<script>
export default {
  name: 'TacheRoiVert',
  components: {
    Logo: () => import('./inline.tache_roi_vert.svg'),
  },
}
</script>
